import { Injectable } from '@angular/core';
import { SessionStorageConstants } from '../../constants/session-storage.constants';
import { LoginCredentials } from '../../data-model/models/login-credentials.model';
import { OssOptional } from '../../utils/oss-optional';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  setLoginCredentials(loginCredentials: LoginCredentials): void {
    sessionStorage.setItem(SessionStorageConstants.LOGIN_CREDENTIALS, JSON.stringify(loginCredentials));
  }

  clearLoginCredentials(): void {
    sessionStorage.removeItem(SessionStorageConstants.LOGIN_CREDENTIALS);
  }

  getLoginCredentials(): LoginCredentials | null {
    return OssOptional.ofNullable(sessionStorage.getItem(SessionStorageConstants.LOGIN_CREDENTIALS)).map(JSON.parse).orElseNull();
  }
}
